@use '@angular/material' as mat;
@use 'sass:map'; // Import sass:map for map functions
@use 'colors';
@use 'palettes';

@include mat.elevation-classes();
@include mat.app-background();

// Define default palettes
$_default-warn-color: map.get($map: colors.$theme-color-defaults, $key: 'warn');
$_warn-palette-map: palettes.generate($_default-warn-color);
$_default-warn-palette : mat.m2-define-palette($_warn-palette-map);
$_default-primary-palette: mat.m2-define-palette(mat.$m2-grey-palette, 900);

@mixin _createTheme($color) {
    $accent-color: map.get($map: colors.$accent-colors, $key: $color);
    $accent-palette-map: palettes.generate($accent-color);
    $accent-palette: mat.m2-define-palette($accent-palette-map);

    $_custom-body-1-level: mat.m2-define-typography-level(
      $font-size: 14px,
      $line-height: 1.5,
      $font-weight: 400
      // $font-family: 'Your Font Family' // Optional: specify font family
    );

    $theme: mat.m2-define-light-theme((
        color: (
            primary: $_default-primary-palette,
            accent: $accent-palette,
            warn: $_default-warn-palette,
        ),
        typography: mat.m2-define-typography-config(
            $body-1: $_custom-body-1-level
            // You can add other levels here: $headline-1, $button, etc.
        ),
        // Configure density directly as a number (e.g., -1)
        density: -1
    ));

    // Apply the theme to the corresponding CSS class
    .theme-#{$color} {
        // Apply the generated theme to all Angular Material components
        @include mat.all-component-themes($theme);

        /* CSS Variables scoped to the theme class */
        --accent-color: #{$accent-color};
        --accent-color-muted: #{colors.createMutedColor($accent-color)};
        --accent-color-medium: #{rgba($accent-color, .75)};
    }
}

// Mixin to generate all themes based on the accent colors map
@mixin createThemes() {
    @each $key in map.keys($map: colors.$accent-colors) {
        @include _createTheme($key);
    }
}
